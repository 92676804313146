import React from 'react'
import { JobPostingsTableWidget as JobPostingsTableWidgetComponent } from '@src/features/JobPostingsTable/JobPostingsTable'
import { MoreBar } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { useNavigateToJobPostingPath } from '@src/features/JobPostingFlow/utils'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { ROUTES } from '@src/constants/routes'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'

const AddJobPostingAction = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddJobDescriptions)
  const navigateToJobPosting = useNavigateToJobPostingPath()
  if (!canAdd) {
    return null
  }
  return (
    <MoreBar.Action
      onClick={() => {
        navigateToJobPosting({})
      }}
      useIcon={Plus}
    >
      Add Job posting
    </MoreBar.Action>
  )
}

const JobPostingActions = () => {
  return (
    <>
      <AddJobPostingAction />
      <SettingsButton
        path={ROUTES.SETTINGS.JOB_POSTING.GENERAL}
        canView={settingsConfig.jobPostings.canView}
      />
    </>
  )
}

export const JobPostingsTableWidget = () => {
  return <JobPostingsTableWidgetComponent actions={<JobPostingActions />} />
}
